import React from "react";



export default function Header(){
    return (
        <header className="header">
        <img className="header-image" src="./images/troll-face.png" alt="" height= "26px" />
        <h2 className="header-title">Meme Generator</h2>
        <h3 className="header-project">React Course - Project 3</h3>

        </header>
    )
}